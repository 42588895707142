<template>
    <div
        class="produkt-img-container relative inset-0 flex items-center justify-center"
    >
        <img
            class="img-fluid"
            :src="$utils.screenRatio() + '/img/product/artline82/artline82.png'"
        />
        <div class="absolute" style="z-index: 1">
            <img
                class="img-fluid"
                :src="
                    $utils.screenRatio() +
                        '/img/product/artline82/artline82-linien.png'
                "
            />
            <div id="artline82-point1" class="artline82-hover-point-left">
                <span>
                    <strong>Verglasungsmöglichkeit</strong><br />
                    von 34 bis 60 mm
                </span>
            </div>
            <div id="artline82-point2" class="artline82-hover-point-right">
                <span>
                    <strong>Hochwärmedämmendes System</strong><br />
                    mit verdeckt liegendem Flügelprofil
                </span>
            </div>
            <div id="artline82-point3" class="artline82-hover-point-left">
                <span>
                    <strong>Glasfalzhöhe</strong><br />
                    von 28 mm reduziert Wärmebrücken
                </span>
            </div>
            <div id="artline82-point4" class="artline82-hover-point-right">
                <span>
                    <strong>Drei Dichtungsebenen</strong><br />
                    gegen Lärm, Kälte, Feuchtigkeit und Zugluft
                </span>
            </div>
            <div id="artline82-point5" class="artline82-hover-point-left">
                <span>
                    <strong>Dämmwerte</strong><br />
                    von bis zu U<sub>w</sub> = 0,67 W/(m²K)* bei Einsatz von
                    hochisolierender Dreifachverglasung mit U<sub>g</sub> 0,4
                    W/(m²K)<br />
                    <small class="mt-4">
                        *mit U<sub>f</sub> = 1,0 W/(m²K), U<sub>g</sub> = 0,4
                        W/(m²K), ψ<sub>g</sub> = 0,035 W/(mK)
                    </small>
                </span>
            </div>
            <div id="artline82-point6" class="artline82-hover-point-right">
                <span>
                    <strong>VEKA-Qualität:<br />Klasse A</strong><br />
                    mit Wandstärken nach DIN EN 12608
                </span>
            </div>
            <div id="artline82-point7" class="artline82-hover-point-right">
                <span>
                    <strong>82-mm-System</strong><br />
                    mit nahezu vollständig überdämmbarem Blendrahmen für eine
                    elegante rahmenlose Ganzglasoptik
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Artline82'
};
</script>

<style>
.produkt-img-container {
    position: relative;
    max-width: 492px;
    margin: 0 auto;
    display: block;
}

.artline82-hover-point-left {
    position: absolute;
    background: url('/../img/icon/pluspunkt-dunkel.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top right;
}

.artline82-hover-point-right {
    position: absolute;
    background: url('/../img/icon/pluspunkt-dunkel.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top left;
}

.artline82-hover-point-left > span,
.artline82-hover-point-right > span {
    display: none;
    position: absolute;
    width: 200px;
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
    padding: 10px;
}

.artline82-hover-point-left sub {
    font-size: 75% !important;
}

.artline82-hover-point-left > span *,
.artline82-hover-point-right > span * {
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
}

.artline82-hover-point-left > span small,
.artline82-hover-point-right > span small {
    color: #ffffff;
    display: inline-block;
    font-size: 0.6em;
    line-height: 1.2em;
    text-align: right;
}

.artline82-hover-point-left > span {
    right: 0;
    text-align: right;
}
.artline82-hover-point-right > span {
    left: 0;
    text-align: left;
}

.artline82-hover-point-left:hover,
.artline82-hover-point-right:hover {
    background: none;
}

.artline82-hover-point-left:hover > span,
.artline82-hover-point-right:hover > span {
    display: block;
    cursor: none;
    background: rgba(0, 0, 0, 0.2);
}

#artline82-point1 > span {
    width: 270px;
}

#artline82-point5 > span {
    width: 270px;
}

#artline82-point1,
#artline82-point3,
#artline82-point5 {
    left: -30px;
}
#artline82-point2,
#artline82-point4,
#artline82-point6 {
    right: -50px;
}
#artline82-point7 {
    bottom: -100px;
}

#artline82-point1 {
    top: 240px;
}

#artline82-point2 {
    top: 254px;
}

#artline82-point3 {
    bottom: 102px;
}

#artline82-point4 {
    bottom: 55px;
}

#artline82-point5 {
    bottom: 10px;
}

#artline82-point6 {
    bottom: 0;
}

#artline82-point7 {
    left: 199px;
}
</style>
