<template>
    <div>
        <div class="flex justify-between items-start">
            <div class="cursor-pointer mr-12" @click="active = '0'">
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline82/softline82-ad-neg.png'
                    "
                    alt="SOFTLINE 82 AD"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 0"
                    :src="$utils.screenRatio() + '/img/icon/line.png'"
                    alt=""
                    style="max-width: 200px"
                />
            </div>
            <div class="cursor-pointer" @click="active = '1'">
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline82/softline82-md-neg.png'
                    "
                    alt="SOFTLINE 82 MD"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 1"
                    :src="$utils.screenRatio() + '/img/icon/line.png'"
                    alt=""
                    style="max-width: 200px"
                />
            </div>
        </div>
        <div class="relative">
            <Softline82ad v-show="active == 0" />
            <Softline82md v-show="active == 1" />
        </div>
    </div>
</template>

<script>
import Softline82ad from '@/components/products/Softline82ad.vue';
import Softline82md from '@/components/products/Softline82md.vue';
export default {
    name: 'Softline82Tabs',
    data() {
        return {
            active: 0
        };
    },
    components: {
        Softline82ad,
        Softline82md
    }
};
</script>

<style></style>
