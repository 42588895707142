var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute",staticStyle:{"top":"50px"},attrs:{"id":"video-tabs"}},[_c('div',{staticClass:"flex justify-between items-start"},[_c('div',{staticClass:"cursor-pointer mr-12",on:{"click":function($event){_vm.toggle(
                    _vm.$utils.screenRatio() +
                        '/video/sequenz-haustuer-sl76_1920x1440.mp4',
                    0
                )}}},[_c('img',{staticStyle:{"max-width":"200px"},attrs:{"src":_vm.$utils.screenRatio() +
                        '/img/product/softline76/softline76-logo.png',"alt":"SOFTLINE 76"}}),(_vm.active == 0)?_c('img',{staticStyle:{"max-width":"200px","margin-top":"15px"},attrs:{"src":_vm.$utils.screenRatio() + '/img/icon/blaue-linie.png',"alt":""}}):_vm._e()]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.toggle(
                    _vm.$utils.screenRatio() +
                        '/video/sequenz-haustuer-sl82_1920x1440.mp4',
                    1
                )}}},[_c('img',{staticStyle:{"max-width":"200px"},attrs:{"src":_vm.$utils.screenRatio() +
                        '/img/product/softline82/softline82-logo.png',"alt":"SOFTLINE 82"}}),(_vm.active == 1)?_c('img',{staticStyle:{"max-width":"200px","margin-top":"15px"},attrs:{"src":_vm.$utils.screenRatio() + '/img/icon/blaue-linie.png',"alt":""}}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }