<template>
    <div
        id="scrollable-container"
        class="overflow-y-auto h-screen"
        ref="scrollableContainer"
    >
        <button
            @click="setFullscreen()"
            id="start"
            class="text-white rounded-full py-2 px-4 uppercase fixed flex items-center"
            style="
                position: absolute;
                cursor: pointer;
                z-index: 99;
                top: 20px;
                font-size: 20px;
            "
        >
            <img
                :src="$utils.screenRatio() + '/img/icon/vollbild-weiss.png'"
                style="max-height: 40px"
                alt=""
            />
        </button>
        <div class="1">
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="2">
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="3">
            <h1>Partnerschaft<br />leben.</h1>
            <h2>Unser Rezept für Ihren Erfolg</h2>
            <div style="height: 80vh"></div>
        </div>
        <div class="4">
            <h1>Gemeinsam nach vorn.</h1>
            <h2>Was Partnerschaft für uns bedeutet</h2>
            <Trigger />
            <p>
                VEKA gibt es mittlerweile seit etwas mehr als 50 Jahren. In
                dieser Zeit hat sich vieles verändert. Wir haben ganz klein
                angefangen, sind gewachsen, international geworden – und
                schließlich die unangefochtene Nummer Eins der Branche.
            </p>
            <p>
                Die ursprüngliche Ausrichtung unseres Unternehmens hat sich
                allerdings nie geändert, denn sie ist die Grundlage unseres
                Erfolgs: Wir bei VEKA haben das Wohl unserer Partner im Blick
                und schauen immer nach vorn. Denn nur, wenn wir
                <strong>zusammen</strong> wachsen und
                <strong>gemeinsam</strong> erfolgreich sind, kann dieser Erfolg
                auch von nachhaltiger Dauer sein.
            </p>

            <p>
                Im Namen des VEKA Vorstandes lade ich Sie ein: Werden Sie ein
                Teil unserer Gemeinschaft, ein vollwertiges Mitglied der VEKA
                Familie. Denn das heißt: Unsere Stärken stehen Ihnen zur
                Verfügung. Und Ihr Erfolg macht wiederum VEKA stärker.
            </p>
            <p>
                Weiter nach vorne. Gemeinsam.<br />
                So definieren wir Partnerschaft.
            </p>
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="5">
            <div class="h-64"></div>
            <Trigger />
            <div class="h-64"></div>
            <Trigger />
        </div>
        <div class="6">
            <h1>Zusammen stark.</h1>
            <h2>Wie wir Partnerschaft organisieren</h2>
            <p>
                Sie wollen das Erfolgsrezept eines Weltmarktführers kennen
                lernen? Ganz einfach: Nie von oben herab, sondern immer auf
                Augenhöhe mit den Partnern und von der Basis her gedacht.
            </p>
            <p>
                Wir konnten so erfolgreich werden, weil wir Ihr Tagesgeschäft
                kennen und noch wissen, wie es auf der Baustelle zugeht. Weil
                wir Märkte erst gründlich kennenlernen, bevor wir sie bedienen.
                Weil wir stets das Wichtigste im Auge behalten: Den Erfolg Ihres
                Geschäftsmodells.
            </p>
            <p>
                Egal, was kommt: Es zählen die Menschen hinter dem Unternehmen.
                Das haben wir immer so gehalten und werden es auch weiterhin
                tun. Versprochen.
            </p>
            <div style="height: 80vh"></div>
        </div>
        <div class="7">
            <Trigger />
            <h1>Gut gemacht.</h1>
            <h2>VEKA-Qualität ist eine Klasse für sich</h2>
            <p>
                Die berühmte VEKA-Qualität: Versprechen und Verpflichtung
                zugleich. Branchenweit anerkannt, weltweit einzigartig. Wie man
                das erreicht? Indem man Standards übertrifft und Grenzen
                verschiebt. Die Ansprüche, die an unsere Produkte gestellt
                werden, werden schließlich immer höher – da wäre es fahrlässig,
                Ihnen etwas anderes als die bestmögliche Qualität anzubieten.
            </p>
            <p>
                Denn VEKA-Qualität sichert Ihren Erfolg – und damit auch
                unseren. Deshalb erstreckt sie sich nicht nur auf unsere
                Profilsysteme, sondern weit darüber hinaus: von Entwicklung und
                Einkauf über Produktion und Logistik bis hin zum Umgang mit
                Mitarbeitern, VEKA Partnern und unserer Umwelt.
            </p>
            <p>
                Nur leere Worte? Keineswegs, alles zertifiziert und
                nachvollziehbar. Etwa nach DIN ISO 9001. Oder nach Klasse A, DIN
                EN 12608. Oder nach Vinyl-Plus- Standard. Oder, oder, oder.
            </p>
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="8">
            <h1>Passend gemacht.</h1>
            <h2>Alles für den modernen Fensterbau</h2>
            <p>
                Als weltweit führender Systemgeber sorgt VEKA dafür, dass immer
                alles zusammenpasst: Für jedes Projekt, jeden Kunden, jede
                Einbausituation haben Sie als VEKA Partner im Handumdrehen die
                richtige Lösung zur Hand. Ein riesiges Warenlager brauchen Sie
                dafür nicht, denn wir bei VEKA bauen auf größtmögliche
                Kompatibilität.
            </p>
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="vielfalt-mit-system">
            <h1>Vielfalt mit System.</h1>
            <h2>Fensterprofilsysteme von VEKA</h2>
            <p>
                Entscheidend für die Leistungsfähigkeit eines Fenstersystems ist
                seine Konstruktion. Wir bieten Systeme für die
                unterschiedlichsten Anforderungen.
            </p>
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="noch-mehr-vielfalt">
            <h1>Noch mehr Vielfalt.</h1>
            <h2>Weitere Profilsysteme</h2>
            <p>
                Die VEKA Systemwelt umfasst neben den Profilsystemen für Fenster
                weitere hochwertige Systemlösungen für Hebe-Schiebetüren,
                Haustüren und Verschattung.
            </p>
            <div class="h-screen"></div>
            <Trigger />
        </div>
        <div class="9">
            <h1 class="text-white">SOFTLINE 82</h1>
            <h2 class="text-white">
                Das perfekte Profilsystem für alle Anforderungen
            </h2>
            <p class="text-white">
                SOFTLINE 82 vereint herausragende Energiespar-Eigenschaften mit
                klassischem Design und extremer Langlebigkeit, Stabilität und
                Sicherheit – sowohl für Ihre Kunden als auch für Ihre Monteure.
                Seine innovative Mehrkammer-Dämmtechnik in 82 mm Bautiefe und
                das hocheffektive Dichtungssystem – erhältlich in AD- oder
                MD-Ausführung – haben Maßstäbe gesetzt und definieren noch immer
                den State of the Art in der Bautiefe über 80 mm.
            </p>
            <div class="h-32"></div>
            <Trigger />
        </div>
        <div class="10">
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="artline-82">
            <h1>ARTLINE 82</h1>
            <h2>Rahmenlose Ganzglasoptik</h2>
            <p>
                Keine Angst vor modernem Design: Mit
                <span style="white-space: nowrap">ARTLINE 82</span> steht Ihnen
                ein System in 82 mm Bautiefe für die gefragte Ganzglas-Bauweise
                zur Verfügung. Der Flügel wird dabei komplett vom Blendrahmen
                verdeckt, der wiederum nahezu vollständig überputzt werden kann.
                So entstehen architektonisch anspruchsvolle Gebäude.
            </p>
            <p>
                <strong style="color: #034991"
                    >Kompatibel zur<br />Systemplattform<br />SOFTLINE
                    82!</strong
                >
            </p>
            <img
                :src="
                    $utils.screenRatio() +
                        '/img/content/artline-kleine-fenster.png'
                "
            />
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="softline-76">
            <h1 class="text-white">SOFTLINE 76</h1>
            <h2 class="text-white">Klassisches Design und hohe Effizienz</h2>
            <p class="text-white">
                Sowohl handwerklich als auch wirtschaftlich effiziente Lösungen
                werden nicht nur im Objektbau immer wichtiger. Unsere Antwort:
                <span class="text-white" style="white-space: nowrap"
                    >SOFTLINE 76</span
                >. Erhältlich mit Mittel- oder Anschlagdichtung und universell
                einsetzbar, wo immer höchste Effizienz und beste Eigenschaften
                gefordert sind.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="vekamotion-82">
            <h1>VEKAMOTION 82</h1>
            <h2>
                Mehr Licht. Mehr Raum.<br />
                Mehr Möglichkeiten:<br />
                die Hebe-Schiebetür, neu <br />
                gedacht
            </h2>
            <p>
                Mit dem von Grund auf neu konzipierten Hebe-Schiebetürsystem
                <span style="white-space: nowrap">VEKAMOTION 82</span> erfüllen
                Sie jeden Kundenwunsch nach modernem Wohnkomfort mit großen
                Glasflächen und geringen Ansichtsbreiten. Und Sie genießen die
                Sicherheit, sämtliche Normen und Vorgaben spielend leicht und
                dabei äußerst wirtschaftlich umsetzen zu können.
            </p>
            <!-- <div class="flex justify-between space-x-4 w-full">
            <img src="img/VEKAMOTION_rgb_pos.png" />
            <img src="img/VEKAMOTION_82_Max_4c_pos.png" />
        </div> -->
            <h2>Ein System – zwei Designvarianten</h2>
            <p>
                Das Hebe-Schiebetürsystem VEKAMOTION 82 bietet bereits in der
                Standardversion großen Glasflächen für viel Licht im Wohnraum.
                Die Designvariante VEKAMOTION 82<sup
                    style="font-size: 14px; line-height: 14px"
                    >MAX</sup
                >
                steigert den Glasanteil mit ihrem neuentwickelten, besonders
                schlanken Festflügel zusätzlich: Durch die Innenansicht von
                lediglich 28 mm wächst die sichtbare Glasfläche um 7 cm in der
                Breite und um 13 cm in der Höhe. Zudem ist die einzigartige
                Rahmenkonstruktion fast vollständig überputzbar, sodass sich
                nahezu rahmenlose Außenansichten erzielen lassen.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="11">
            <h1>
                Haus&shy;eingangs&shy;türen mit SOFTLINE 76<br />
                und SOFTLINE 82
            </h1>
            <h2>
                125-mm-Flügel für den
                <span
                    style="
                        white-space: nowrap;
                        font-family: 'Rotis II Sans W04 Bold';
                        color: rgba(0, 0, 0, 90);
                        font-size: 26px;
                        line-height: 28px;
                        margin: 10px 0 20px 0;
                    "
                    >Türenbau</span
                >
            </h2>
            <p>
                Haustüren müssen immer zwei Dinge gleichzeitig sein: Die
                Projektionsfläche für individuelle Gestaltung und praktisch
                funktioneller Eingang zum eigenen Haus. Mit Profilsystemen von
                VEKA realisieren Sie, was Ihre Kunden wollen. Selbstverständlich
                in VEKA-Qualität und vom Design her ganz genau abgestimmt auf
                unsere Fenstersysteme.
            </p>
            <div class="h-32"></div>
            <h2>Haustüren mit flügelüberdeckenden Füllungen</h2>
            <p>
                Ein spezieller Flügel für Hauseingangstüren mit
                flügelüberdeckenden Füllungen macht es Ihnen leicht, die
                neuesten Designtrends zu bedienen – und die ausgefallensten
                Kundenwünsche.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <!-- <div class="12">
            <div style="height: 80vh"></div>
            <Trigger />
        </div> -->
        <div class="vekavariant2-0">
            <h1 class="text-white" style="white-space: nowrap">
                VEKAVARIANT 2.0
            </h1>
            <p class="text-white">
                VEKA bietet vom Profilsystem über den Rollladenkasten bis zum
                Rollladenpanzer alle Komponenten – sorgfältig aufeinander
                abgestimmt und aus einer Hand. Da passt alles zusammen.
            </p>
            <div style="height: 40vh"></div>
        </div>
        <div class="das-rollladenkastensystem-fuer-jede-anforderung">
            <h2 class="text-white">
                Das Rollladenkastensystem für jede Anforderung
            </h2>
            <p class="text-white">
                Aus der Praxis gelernt: Als umfassend optimierte Systemlösung
                steht der Rollladenkasten
                <span class="text-white" style="white-space: nowrap"
                    >VEKAVARIANT 2.0</span
                >
                für sichere Verarbeitung und mühelose Montage. Natürlich in
                VEKA-Qualität. Komfortabel zu verarbeiten, überzeugt durch seine
                enorme Vielseitigkeit und Flexibilität für jede Einbausituation.
                Mit drei verschiedenen Bauhöhen (175 mm, 210 mm und 235 mm) und
                drei Bautiefen (230 mm, 250 mm und 350 mm) besonders flexibel
                einsetzbar.
            </p>
            <Trigger />
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="veka-sichtschutzsysteme">
            <h2 class="text-white">VEKA Sichtschutzsysteme</h2>
            <p class="text-white">
                Für die Praxis gemacht: Das umfassende VEKA Produktsystem
                erlaubt die reibungslose Umsetzung moderner Sichtschutzlösungen:
            </p>
            <ul class="list-disc list-outside ml-6">
                <li class="text-white">Rollläden, Textilscreens, Raffstores</li>
                <li class="text-white">Gurt-, Kurbel- und Motorbedienung</li>
                <li class="text-white">
                    VEKAVARIANT 2.0 und Rollladenkästen anderer Anbieter
                </li>
                <li class="text-white">
                    neuentwickelte Rollladenführungen für jede Einbausituation
                </li>
            </ul>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="14">
            <h2>Rollladenstäbe und -führungen für alle Wünsche</h2>
            <p>
                Reicher Erfahrungsschatz: Wussten Sie, dass Rollladenstäbe das
                erste Produkt von VEKA waren? Auch heute noch sind unsere
                Rollladenprofile M37, K51 und K52 ein wichtiger Teil des
                Produktportfolios. Sie sind in verschiedenen Geometrien und
                Farben sowie mit umfangreichen Zubehör verfügbar – für
                buchstäblich für alle Einsatzbereiche in Neubau und Renovierung.
            </p>
            <p>
                Gefärbt mit Cool-Colour-Pigmenten, die einer Aufheizung im
                Sonnenlicht entgegenwirken und die thermische Belastung des
                Rollladenpanzers reduzieren. Dazu gibt es das wohl
                umfangreichste und mit einem klar erkennbaren Systemgedanken
                entwickelte Programm an Rollladenführungen der ganzen Branche.
                Hier bietet keiner mehr.
            </p>
            <img
                :src="
                    $utils.screenRatio() +
                        '/img/content/rolladenstaebe-zeichnung.png'
                "
            />
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="ihre-kunden-werden-augen-machen">
            <h1>
                Ihre Kunden <br />werden Augen<br />
                machen!
            </h1>
            <p>
                Kennen Sie unsere Kampagne zur Markteinführung von
                <span style="white-space: nowrap">VEKAMOTION 82</span>? Wir
                unterstützen unsere Partner mit individuellen Marketing-Paketen.
                So fällt es Ihnen leicht, große Aufmerksamkeit für Ihr
                Leistungs-Portfolio zu generieren!
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="exklusiv-fuer-veka-partner-veka-spectral">
            <h1 class="text-white">
                Oberflächen&shy;gestalt&shy;ung mit VEKA Profilen
            </h1>
            <h2 class="text-white">
                Exklusiv für VEKA Partner:<br />VEKA SPECTRAL
            </h2>
            <p class="text-white">
                Optisch faszinierend. Haptisch unwiderstehlich. Technisch
                ausgereift.
                <span class="text-white" style="white-space: nowrap"
                    >VEKA SPECTRAL</span
                >
                ist eine neue Stufe technischen Designs – exklusiv für Sie als
                VEKA Partner. Ein weiteres, echtes Alleinstellungsmerkmal. Mit
                <span class="text-white" style="white-space: nowrap"
                    >VEKA SPECTRAL</span
                >
                bieten Sie Ihren Kunden die Symbiose von Designanspruch und
                Technik in Perfektion. Und wir bieten Ihnen 10 Jahre Garantie
                auf die Witterungsbeständigkeit.
                <span class="text-white" style="white-space: nowrap"
                    >VEKA SPECTRAL</span
                >
                müssen Sie erleben.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="oberflaechengestaltung-mit-veka-profilen">
            <h2>Fenster in Farbe:<br />Farb- und Dekorfolien</h2>
            <p>
                &bdquo;Fenster in Farbe? Kein Problem.&ldquo; Das unschlagbar
                umfangreiche VEKA Sortiment an Farb- und Dekorfolien macht Ihr
                Verkaufsgespräch zum Selbstläufer, denn letztlich gibt es bei
                VEKA nichts, was es nicht gibt. Was es für VEKA Partner auch
                nicht gibt: Lieferschwierigkeiten. Wir haben ein riesiges Lager
                und bevorraten uns stets mit den gängigsten Farben, sind aber
                auch bei Sonderfarben von der schnellen Truppe – probieren Sie
                es aus!
            </p>
            <p>
                Was es für VEKA Partner auch nicht gibt: Lieferschwierigkeiten.
                Wir haben ein riesiges Lager und bevorraten uns stets mit den
                gängigsten Farben, sind aber auch bei Sonderfarben von der
                schnellen Truppe – probieren Sie es aus!
            </p>
            <img
                :src="$utils.screenRatio() + '/img/content/bunte-fenster.png'"
                class="mt-16"
            />
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="robust-und-wertig-alu-vorsatzblenden">
            <h2>
                Robust und wertig:<br />
                Alu-Vorsatzblenden
            </h2>
            <p>
                Möglich ist, was vorstellbar ist: Alle RAL-Farben. Für alle
                Einsatzbereiche, Fenster, Türen, Hebe-Schiebetüren.
                Alu-Vorsatzblenden vereinen die technischen Vorzüge unserer
                Klasse-A-Kunststoffprofile mit der hochwertigen Optik und
                Beständigkeit des Aluminiums. Und die Montage ist fast schon ein
                Kinderspiel: Klips – fertig. Kunde zufrieden.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>

        <div class="15">
            <h1>Zusammen<br />wachsen</h1>
            <h2>Wie Sie von unserer Größe <br />profitieren</h2>
            <p>
                Die Familie ist groß geworden. Und international: Als
                Unternehmensgruppe ist VEKA heute mit 40 Standorten auf vier
                Kontinenten präsent. Rund 6.200 Mitarbeiter, davon allein 1.400
                am Stammsitz in Sendenhorst, erwirtschaften Jahr für Jahr einen
                Milliardenumsatz. Und diese Stärke strahlt auf unsere Partner
                ab.
            </p>
            <p>
                Erstens ganz direkt: Wir können bei Forschung, Entwicklung,
                Materialbeschaffung, Gebrauchstauglichkeitstests und vielem mehr
                Synergieeffekte nutzen. Sie merken davon nur, dass neue VEKA
                Produkte durchdacht und ausgereift sind, wenn sie auf den Markt
                kommen.
            </p>
            <p>
                Zweitens profitieren Sie von unserer Verlässlichkeit und
                Stabilität – was auch immer kommt. Denn VEKA ist breit
                aufgestellt, finanziell unabhängig und damit ein grundsolider
                Partner, der Orientierung gibt, auch in unsicheren Zeiten: In
                einer Familie ist man füreinander da.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="exklusiv-fuer-veka-partner">
            <h1>Verlassen Sie sich drauf.</h1>
            <h2>Exklusiv für VEKA Partner</h2>
            <p>Unser umfassender Service:</p>
            <ul class="list-disc list-outside ml-6">
                <li>Feste Ansprechpartner in Vertrieb und Abwicklung</li>
                <li>Technische Unterstützung vor Ort</li>
                <li>Einarbeitung und Schulung in Sendenhorst</li>
                <li>Marketingservice</li>
                <li>Seminare und Fachtagungen</li>
                <li>Digitale Services</li>
                <li>und vieles mehr</li>
            </ul>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="16">
            <h1>Gemeinsam Gutes tun.</h1>
            <h2>Nachhaltigkeit als klare Verkaufsbotschaft</h2>
            <p>
                VEKA handelt verantwortungsvoll und setzt auf Nachhaltigkeit.
                Und Sie können diese klare Botschaft an Ihre Kunden weitergeben.
                Mit Brief und Siegel bzw. Zertifikat:
            </p>
            <ul class="list-disc list-outside ml-6">
                <li>VEKA kauft nur bei zertifizierten Lieferanten ein</li>
                <li>
                    VEKA betreibt ein Energiemanagement-System nach ISO 50001
                </li>
                <li>
                    VEKA setzt bei Hauptprofilen durchschnittlich rund 50 %
                    Rezyklat ein.
                </li>
                <li>
                    Produktlabel der europäischen Nachhaltigkeitsinitiative
                    VinylPlus für die gesamte SOFTLINE-Plattform
                </li>
                <li>VEKA ist Mitglied bei VinylPlus, REWINDO und EPPA</li>
            </ul>
            <p>
                Außerdem ist die VEKA Umwelttechnik ein echter Recyclingpionier:
                aus alt mach neu. Und Sie wissen immer, wohin mit den
                ausgebauten Altfenstern Ihrer Kunden.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
        </div>
        <div class="ganz-individuell">
            <h1 class="text-white">Ganz individuell.</h1>
            <h2 class="text-white">
                Eine Umstellung ist keine Kleinigkeit. Aber gut machbar.
            </h2>
            <p class="text-white">
                Wenn Sie sich für VEKA entschieden haben, entwickeln wir
                gemeinsam einen detaillierten Projektplan für die Umstellung. Da
                ist nichts von der Stange, sondern immer exakt auf Ihr
                Unternehmen zugeschnitten. Wir betrachten die einzelnen
                Teilbereiche und legen zusammen die anstehenden Aufgaben,
                Termine und Ansprechpartner fest. So können Sie sich direkt mit
                eigenen Augen von unserer bodenständigen und immer verbindlichen
                Art zu arbeiten überzeugen. Und noch eines ist klar: Wir sind
                den ganzen Prozess über – und auch danach – immer für Sie da.
                Versprochen.
            </p>
            <div style="height: 80vh"></div>
            <Trigger />
            <div style="height: 100vh"></div>
        </div>
        <div
            id="scroll-to-continue"
            class="text-white rounded-full py-2 px-4 uppercase fixed flex items-center h-18"
        >
            <img
                :src="$utils.screenRatio() + '/img/icon/scroll-weiss.png'"
                style="max-height: 40px"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import Reveal from 'reveal.js';
import Trigger from '@/components/Trigger';
export default {
    name: 'ScrollableText',
    data() {
        return {
            triggers: null,
            timer: null
        };
    },
    components: {
        Trigger
    },
    mounted() {
        this.getTriggers();
        this.$el.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        this.$el.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        isElementInViewport(elem) {
            var el = elem.element.getBoundingClientRect();
            return (
                el.top >= 0 &&
                el.left >= 0 &&
                el.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                el.right <=
                    (window.innerWidth ||
                        document.documentElement.clientWidth) &&
                elem.fired == false
            );
        },
        isElementAboveBottom(elem) {
            var el = elem.element.getBoundingClientRect();
            return (
                el.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                elem.fired == false
            );
        },
        isElementBelowBottom(elem) {
            var el = elem.element.getBoundingClientRect();
            return (
                el.bottom >=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                elem.fired == true
            );
        },
        handleScroll() {
            var that = this;
            Array.from(this.triggers).forEach(function(trigger) {
                if (that.isElementAboveBottom(trigger)) {
                    Reveal.next();
                    trigger.fired = true;
                    that.replayVideos();
                    that.elementAnimation();
                }

                if (that.isElementBelowBottom(trigger)) {
                    Reveal.prev();
                    trigger.fired = false;
                    that.replayVideos();
                    that.elementAnimation();
                }
            });
            this.showScrollHint();

            var start = document.getElementById('start');

            start.classList.remove('hidden');

            if (this.isInFullscreen()) {
                start.classList.add('hidden');
            }
        },
        getTriggers() {
            var triggerElements = document.getElementsByClassName('trigger');
            var triggers = [];

            Array.from(triggerElements).forEach(function(trigger) {
                triggers.push({
                    element: trigger,
                    fired: false
                });
            });

            this.triggers = triggers;
        },
        replayVideos() {
            var slide = Reveal.getCurrentSlide();
            var vids = slide.getElementsByTagName('video');
            for (var i = 0; i < vids.length; i++) {
                vids[i].currentTime = 0;
                // vids[i].load();
                vids[i].pause();
                var video = vids[i];
                var timeout = 0;
                if (video.dataset.wait) {
                    timeout = video.dataset.wait;
                }
                setTimeout(
                    function() {
                        var playPromise = video.play();
                        if (playPromise !== undefined) {
                            playPromise
                                .then(function() {
                                    // Automatic playback started!
                                })
                                .catch(function() {});
                        }
                    },
                    timeout,
                    video
                );
                if (video.dataset.stop) {
                    video.addEventListener('timeupdate', function() {
                        if (
                            this.currentTime >=
                            parseInt(video.dataset.stop) / 1000
                        ) {
                            this.pause();
                        }
                    });
                }
            }
        },
        elementAnimation() {
            var slide = Reveal.getCurrentSlide();
            var element = slide.querySelector('.element');
            this.toggleOpacity(slide, '.element');
            var that = this;
            if (element && element.dataset.wait) {
                setTimeout(
                    function() {
                        that.toggleOpacity(slide, '.element');
                    },
                    element.dataset.wait,
                    slide
                );
            }
        },
        toggleOpacity(slide, selector) {
            var element = slide.querySelector(selector);
            if (element) {
                if (element.classList.contains('opacity-0')) {
                    element.classList.remove('opacity-0');
                    element.classList.add('opacity-100');
                } else if (element.classList.contains('opacity-100')) {
                    element.classList.add('opacity-0');
                    element.classList.remove('opacity-100');
                }
            }
        },
        showScrollHint() {
            var scrollToContinue = document.getElementById(
                'scroll-to-continue'
            );
            if (!scrollToContinue.classList.contains('hidden')) {
                scrollToContinue.classList.add('hidden');
            }
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(function() {
                scrollToContinue.classList.remove('hidden');
            }, 5000);
        },
        isIos() {
            return (
                /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
            );
        },
        setFullscreen() {
            var el = document.documentElement;
            if (el.requestFullscreen) {
                el.requestFullscreen();
            } else if (el.mozRequestFullScreen) {
                el.mozRequestFullScreen();
            } else if (el.msRequestFullscreen) {
                el.msRequestFullscreen();
            } else if (el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen();
            }
            // Reveal.down();
            var start = document.getElementById('start');

            start.classList.add('hidden');
        },
        isInFullscreen() {
            return (
                window.fullScreen ||
                (window.innerWidth == screen.width &&
                    window.innerHeight == screen.height)
            );
        }
    }
};
</script>

<style>
#scrollable-container::-webkit-scrollbar {
    display: none;
}

#scrollable-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: fixed;
    padding: 3rem;
    width: 40%;
    height: 100%;
    z-index: 98;
    overflow-x: hidden;
}
#scroll-to-continue {
    bottom: 20px;
}
</style>
