<template>
    <div>
        <Presentation />
    </div>
</template>

<script>
import Presentation from '@/components/Presentation.vue';

export default {
    name: 'Home',
    components: {
        Presentation
    }
};
</script>
