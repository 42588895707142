<template>
    <div>
        <div class="flex justify-between items-start">
            <div class="cursor-pointer mr-12" @click="active = '0'">
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline76/softline76_ad_4c_neg.png'
                    "
                    alt="SOFTLINE 76 AD"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 0"
                    :src="$utils.screenRatio() + '/img/icon/line.png'"
                    alt=""
                    style="max-width: 200px"
                />
            </div>
            <div class="cursor-pointer" @click="active = '1'">
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline76/softline76_md_4c_neg.png'
                    "
                    alt="SOFTLINE 76 MD"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 1"
                    :src="'/img/icon/line.png'"
                    alt=""
                    style="max-width: 200px"
                />
            </div>
        </div>
        <div class="relative">
            <Softline76ad v-if="active == 0" />
            <Softline76md v-if="active == 1" />
        </div>
    </div>
</template>

<script>
import Softline76ad from '@/components/products/Softline76ad.vue';
import Softline76md from '@/components/products/Softline76md.vue';
export default {
    name: 'Softline76Tabs',
    data() {
        return {
            active: 0
        };
    },
    components: {
        Softline76ad,
        Softline76md
    }
};
</script>

<style></style>
