<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    mounted() {
        console.log(this.$utils.screenRatio());
    }
};
</script>
