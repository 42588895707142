const RATIOS = {
    '16:10': 1.6, // 1920/1200 = 1.6
    '4:3': 1.333333333 // 1920/1440 = 1.333333333
};

export const utils = {
    closest: (width, height) => {
        var find = width / height;
        return Object.values(RATIOS).reduce(function(prev, curr) {
            return Math.abs(curr - find) < Math.abs(prev - find) ? curr : prev;
        });
    },
    closestRatio: (width, height) => {
        return Object.keys(RATIOS).find(
            key => RATIOS[key] === utils.closest(width, height)
        );
    },
    screenRatio: () => {
        return utils.closestRatio(
            window.screen.width * window.devicePixelRatio,
            window.screen.height * window.devicePixelRatio
        );
    }
};
