<template>
    <div
        class="produkt-img-container relative inset-0 flex items-center justify-center"
    >
        <img
            class="img-fluid"
            :src="
                $utils.screenRatio() +
                    '/img/product/softline76/softline76-md.png'
            "
        />
        <div class="absolute" style="z-index: 1">
            <img
                class="img-fluid"
                :src="
                    $utils.screenRatio() +
                        '/img/product/softline76/softline76-md-linien.png'
                "
            />
            <div id="softline76md-point1" class="softline76md-hover-point-left">
                <span>
                    <strong>Verglasungsstärken</strong><br />
                    von 18 bis 48 mm
                </span>
            </div>
            <div
                id="softline76md-point2"
                class="softline76md-hover-point-right"
            >
                <span>
                    <strong>Vielfältige Designoptionen:</strong><br />
                    VEKA SPECTRAL, Folien und Vorsatzschalen möglich
                </span>
            </div>
            <!-- <div id="point3" class="hover-point-left">
                <span>
                    <strong>Besonders pflegeleicht </strong><br />
                    durch gerade Fläche zwischen <br />
                    Flügel und Blendrahmen
                </span>
            </div> -->
            <div
                id="softline76md-point4"
                class="softline76md-hover-point-right"
            >
                <span>
                    <strong>Drei Dichtungsebenen</strong><br />
                    als Schutz gegen Lärm, Kälte, Feuchtigkeit und Zugluft
                </span>
            </div>
            <div id="softline76md-point5" class="softline76md-hover-point-left">
                <span>
                    <strong>Dämmwerte</strong><br />
                    von bis zu U<sub>w</sub> = 0,75 W/(m²K)* bei Einsatz von
                    hochisolierender Dreifachverglasung mit U<sub>g</sub> = 0,4
                    W/(m²K)
                </span>
            </div>
            <div
                id="softline76md-point6"
                class="softline76md-hover-point-right"
            >
                <span>
                    <strong>VEKA-Qualität:<br />Klasse A</strong><br />
                    mit Wandstärken nach DIN EN 12608
                </span>
            </div>
            <div
                id="softline76md-point7"
                class="softline76md-hover-point-right"
            >
                <span>
                    <strong>Mehrkammer-System</strong><br />
                    mit 76 mm Basis-Bautiefe für hervorragende Wärmedämmung,
                    universell einsetzbar für Neubau und Renovierung
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Softline76md'
};
</script>

<style>
.produkt-img-container {
    position: relative;
    max-width: 492px;
    margin: 0 auto;
    display: block;
}

.softline76md-hover-point-left {
    position: absolute;
    background: url('/../img/icon/pluspunkt.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top right;
}

.softline76md-hover-point-right {
    position: absolute;
    background: url('/../img/icon/pluspunkt.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top left;
}

.softline76md-hover-point-left > span,
.softline76md-hover-point-right > span {
    display: none;
    position: absolute;
    width: 200px;
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
    padding: 10px;
}

.softline76md-hover-point-left sub {
    font-size: 75% !important;
}

.softline76md-hover-point-left > span *,
.softline76md-hover-point-right > span * {
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
}

.softline82md-hover-point-left > span small,
.softline82md-hover-point-right > span small {
    color: #ffffff;
    display: inline-block;
    font-size: 0.6em;
    line-height: 1.2em;
    text-align: right;
}

.softline76md-hover-point-left > span {
    right: 0;
    text-align: right;
}
.softline76md-hover-point-right > span {
    left: 0;
    text-align: left;
}

.softline76md-hover-point-left:hover,
.softline76md-hover-point-right:hover {
    background: none;
}

.softline76md-hover-point-left:hover > span,
.softline76md-hover-point-right:hover > span {
    display: block;
    cursor: none;
    background: rgba(0, 0, 0, 0.2);
}

#softline76md-point5 > span {
    width: 270px;
}

#softline76md-point1,
#softline76md-point3,
#softline76md-point5 {
    left: -30px;
}
#softline76md-point2,
#softline76md-point4,
#softline76md-point6 {
    right: -25px;
}
#softline76md-point7 {
    bottom: -100px;
}

#softline76md-point1 {
    top: 180px;
}

#softline76md-point2 {
    top: 153px;
}

#softline76md-point3 {
    bottom: 66px;
}

#softline76md-point4 {
    bottom: 78px;
}

#softline76md-point5 {
    bottom: -3px;
}

#softline76md-point6 {
    bottom: -3px;
}

#softline76md-point7 {
    left: 202px;
}
</style>
