<template>
    <div :id="id" class="trigger"></div>
</template>

<script>
export default {
    name: 'Trigger',
    data() {
        return {
            id: null
        };
    },
    mounted() {
        this.id = 'trigger' + '-' + this._uid;
    }
};
</script>
