<template>
    <div
        class="produkt-img-container relative inset-0 flex items-center justify-center"
    >
        <img
            class="img-fluid"
            :src="
                $utils.screenRatio() +
                    '/img/product/softline82/softline82-ad.png'
            "
        />
        <div class="absolute" style="z-index: 1">
            <img
                class="img-fluid"
                :src="
                    $utils.screenRatio() +
                        '/img/product/softline82/softline82-ad-linien.png'
                "
            />
            <div id="softline82ad-point1" class="softline82ad-hover-point-left">
                <span>
                    <strong>Glasfalzhöhe</strong><br />
                    von 28 mm reduziert Wärmebrücken
                </span>
            </div>
            <div
                id="softline82ad-point2"
                class="softline82ad-hover-point-right"
            >
                <span>
                    <strong>Oberflächenveredelung</strong><br />
                    mit VEKA SPECTRAL, Folien und Vorsatzschalen möglich
                </span>
            </div>
            <div id="softline82ad-point3" class="softline82ad-hover-point-left">
                <span>
                    <strong>Besonders pflegeleicht </strong><br />
                    durch gerade Fläche<br />
                    zwischen Flügel und Blendrahmen
                </span>
            </div>
            <div
                id="softline82ad-point4"
                class="softline82ad-hover-point-right"
            >
                <span>
                    <strong>Klassisches Design</strong><br />
                    und geradlinige Optik
                </span>
            </div>
            <div id="softline82ad-point5" class="softline82ad-hover-point-left">
                <span>
                    <strong>Dämmwerte</strong><br />
                    von bis zu U<sub>w</sub> = 0,71 W/(m²K)* bei Einsatz von
                    hochisolierender Dreifachverglasung mit U<sub>g</sub>
                    = 0,4 W/(m²K)
                    <small class="mt-4">
                        *mit U<sub>f</sub> = 1,1 W/(m²K), U<sub>g</sub> = 0,4
                        W/(m²K), ψ<sub>g</sub> = 0,035 W/(mK)
                    </small>
                </span>
            </div>
            <div
                id="softline82ad-point6"
                class="softline82ad-hover-point-right"
            >
                <span>
                    <strong
                        >VEKA-Qualität:<br />
                        Klasse A</strong
                    ><br />
                    mit Wandstärken nach DIN EN 12608
                </span>
            </div>
            <div
                id="softline82ad-point7"
                class="softline82ad-hover-point-right"
            >
                <span>
                    <strong>82-mm-System</strong><br />
                    für hervorragende Wärmedämmung – universell einsetzbar für
                    Neubau und Renovierung
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Softline82ad'
};
</script>

<style>
.produkt-img-container {
    position: relative;
    max-width: 492px;
    margin: 0 auto;
    display: block;
}

.softline82ad-hover-point-left {
    position: absolute;
    background: url('/../img/icon/pluspunkt.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top right;
}

.softline82ad-hover-point-right {
    position: absolute;
    background: url('/../img/icon/pluspunkt.png') no-repeat;
    width: 100px;
    height: 100px;
    background-position: top left;
}

.softline82ad-hover-point-left > span,
.softline82ad-hover-point-right > span {
    display: none;
    position: absolute;
    width: 200px;
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
    padding: 10px;
}

.softline82ad-hover-point-left > span *,
.softline82ad-hover-point-right > span * {
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
}

.softline82ad-hover-point-left sub {
    font-size: 75% !important;
}

.softline82ad-hover-point-left > span small,
.softline82ad-hover-point-right > span small {
    color: #ffffff;
    display: inline-block;
    font-size: 0.6em;
    line-height: 1.2em;
    text-align: right;
}

.softline82ad-hover-point-left > span {
    right: 0;
    text-align: right;
}
.softline82ad-hover-point-right > span {
    left: 0;
    text-align: left;
}

.softline82ad-hover-point-left:hover,
.softline82ad-hover-point-right:hover {
    background: none;
}

.softline82ad-hover-point-left:hover > span,
.softline82ad-hover-point-right:hover > span {
    display: block;
    cursor: none;
    background: rgba(0, 0, 0, 0.2);
}

#softline82ad-point5 > span {
    width: 270px;
}

#softline82ad-point1,
#softline82ad-point3,
#softline82ad-point5 {
    left: -30px;
}
#softline82ad-point2,
#softline82ad-point4,
#softline82ad-point6 {
    right: -25px;
}
#softline82ad-point7 {
    bottom: -100px;
}

#softline82ad-point1 {
    top: 189px;
}

#softline82ad-point2 {
    top: 112px;
}

#softline82ad-point3 {
    bottom: 66px;
}

#softline82ad-point4 {
    bottom: 85px;
}

#softline82ad-point5 {
    bottom: 5px;
}

#softline82ad-point6 {
    bottom: 0;
}

#softline82ad-point7 {
    left: 202px;
}
</style>
