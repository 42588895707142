<template>
    <div>
        <Navigation />
        <ScrollableText />
        <div class="reveal">
            <div class="slides">
                <section>
                    <section
                        class="1"
                        data-transition="slide"
                        data-autoslide="0"
                    >
                        <img
                            class="object-cover h-screen w-full"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/loading-veka_1920x1440.jpg'
                            "
                        />
                        <!-- <img
                            id="logo-startseite"
                            :data-src="
                                $utils.screenRatio() +
                                '/img/logo/veka-logo-negativ.png'
                            "
                        /> -->
                    </section>
                    <section
                        class="2"
                        data-transition="fade"
                        style="transition-duration: 2s"
                        data-autoslide="4000"
                    >
                        <video
                            preload="none"
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/welcome-start_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/welcome-start_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="3"
                        data-transition="fade"
                        data-autoslide="0"
                        style="transition-duration: 2s"
                    >
                        <img
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/veka-werk_1920x1440.jpg'
                            "
                            class="object-cover w-full"
                        />
                        <!-- <img
                            style="
                                bottom: 20px;
                                right: 20px;
                                position: absolute;
                            "
                            data-src="img/logo/veka-footer_300x127.png"
                        /> -->
                    </section>
                    <section
                        class="4"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            preload="none"
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-stop="20000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/fliegende-enten_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/fliegende-enten_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-0 content-container absolute flex items-center justify-center"
                            style="top: 50px"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/ehepaar-hartleif-gemeinsam-nach-vorn.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="5"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <img
                            class="object-cover h-full w-full"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/vorstand-ohne-namen_1920x1440.jpg'
                            "
                        />
                        <img
                            class="object-cover h-full w-full fragment fade-in"
                            style="z-index: 0"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/vorstand-mit-namen_1920x1440.jpg'
                            "
                        />
                    </section>
                    <section
                        class="6"
                        data-transition="fade"
                        data-autoslide="0"
                        style="transition-duration: 2s"
                    >
                        <video
                            preload="none"
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            muted
                            class="object-cover h-screen w-full"
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/zusammen-stark_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/zusammen-stark_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/beckhoff-zusammen-stark.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="7"
                        data-transition="fade"
                        data-autoslide="500"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-gut-gemacht-sterne_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-gut-gemacht-sterne_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/schuler-gut-gemacht-sterne.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="8"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <img
                            class="object-cover h-full w-full"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/passend-gemacht_1920x1440.jpg'
                            "
                        />
                        <div
                            class="element transition-all duration-500 ease-in-out content-container absolute inset-0 flex items-center justify-center opacity-0"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/passend-gemacht.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="vielfalt-mit-system"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/background/passend-gemacht_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/passend-gemacht_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="noch-mehr-vielfalt"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/background/passend-gemacht_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/vielfalt-mit-system_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="9"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-profil_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-profil_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="10"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <img
                            class="object-cover h-screen w-full"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/standbild-softline82_1920x1080.jpg'
                            "
                        />
                        <div
                            class="content-container absolute inset-0 flex items-center justify-center"
                        >
                            <Softline82Tabs />
                        </div>
                    </section>
                    <section
                        class="artline-82"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-artline_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-artline_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-100 content-container absolute inset-0 flex items-center justify-center"
                            data-wait="3500"
                        >
                            <div class="relative">
                                <div class="flex justify-end">
                                    <img
                                        style="
                                            max-width: 200px;
                                            max-height: 45px;
                                        "
                                        :data-src="
                                            $utils.screenRatio() +
                                                '/img/product/artline82/artline82-logo.png'
                                        "
                                    />
                                </div>
                                <Artline82 />
                            </div>
                        </div>
                    </section>
                    <section
                        class="softline-76"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <img
                            class="object-cover h-screen w-full"
                            :data-src="
                                $utils.screenRatio() +
                                    '/img/background/einfamilienhaus-dunkel_1920x1440.jpg'
                            "
                        />
                        <div
                            class="content-container absolute inset-0 flex items-center justify-center"
                        >
                            <Softline76Tabs />
                        </div>
                    </section>
                    <section
                        class="vekamotion-82"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            loop
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/vekamotion-bilder-sequenz_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/vekamotion-bilder-sequenz_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="11"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-haustueren_1920x1440.jpg'
                            "
                            id="sequenz-haustueren"
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-haustueren_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-100 content-container absolute inset-0 flex items-center justify-center"
                            data-wait="5000"
                        >
                            <VideoTabs />
                        </div>
                    </section>
                    <!-- <section
                        class="12"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <img
                            class="object-cover h-screen w-full"
                            :data-src="
                                $utils.screenRatio() +
                                '/img/background/haustuer-hintergrund-musterecke_1920x1440.png'
                            "
                        />
                    </section> -->
                    <section
                        class="vekavariant2-0"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/vekavariant-rolladenkasten_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/vekavariant-rolladenkasten_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="das-rollladenkastensystem-fuer-jede-anforderung"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-vekavariant-diagramm_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="veka-sichtschutzsysteme"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-sichtschutzsysteme_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-sichtschutzsysteme_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="14"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            loop
                            muted
                            data-stop="3000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/rolladenstaebe_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/rolladenstaebe_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="ihre-kunden-werden-augen-machen"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <PrevNext
                            class="absolute z-10"
                            style="top: 50px; right: 50px"
                            @activeTab="setVideoSrc($event)"
                        />
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            muted
                            class="object-cover h-screen w-full"
                            ref="videoBackground"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-werbemassnahmen-alle_1920x1440.jpg'
                            "
                        >
                            <source
                                ref="videoSrc"
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-werbemassnahmen-1_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="exklusiv-fuer-veka-partner-veka-spectral"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-vekaspectral_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-vekaspectral_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="oberflaechengestaltung-mit-veka-profilen"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-oberflaechengestaltung_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-oberflaechengestaltung_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="robust-und-wertig-alu-vorsatzblenden"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            loop
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-alu-vorsatzblenden_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-alu-vorsatzblenden_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                    <section
                        class="15"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-zusammen-wachsen_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-zusammen-wachsen_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out content-container absolute flex items-center justify-center opacity-0"
                            style="top: 50px"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/zusammen-wachsen.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="exklusiv-fuer-veka-partner"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            style="position: absolute; top: 0; right: 0"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-dbs-logos_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-dbs-logos_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out content-container absolute inset-0 flex items-center justify-center opacity-0"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/scholle-gemeinsam.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="16"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-nachhaltigkeit_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-nachhaltigkeit_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-0 content-container absolute flex items-center justify-center"
                            style="top: 50px"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/hartleif-nachhaltigkeit.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="ganz-individuell"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <!-- <img
                            class="element transition-all duration-500 ease-in-out opacity-0 object-cover h-full w-full"
                            data-src="/img/poster/sequenz-tabelle-projektteam_1920x1440.jpg"
                            data-wait="4000"
                        /> -->
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            data-wait="4000"
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/sequenz-tabelle-projektteam_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/sequenz-tabelle-projektteam_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                        <div
                            class="element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center"
                            data-wait="4000"
                        >
                            <img
                                :data-src="
                                    $utils.screenRatio() +
                                        '/img/zitat/hartleif-tabelle-projektteam.png'
                                "
                            />
                        </div>
                    </section>
                    <section
                        class="veka-ende"
                        data-transition="fade"
                        style="transition-duration: 2s"
                    >
                        <video
                            playsinline
                            autobuffer
                            crossOrigin="anonymous"
                            class="object-cover h-screen w-full"
                            muted
                            :poster="
                                $utils.screenRatio() +
                                    '/img/poster/abschluss_1920x1440.jpg'
                            "
                        >
                            <source
                                :data-src="
                                    $utils.screenRatio() +
                                        '/video/abschluss_1920x1440.mp4'
                                "
                                type="video/mp4"
                            />
                        </video>
                    </section>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import Reveal from 'reveal.js';
import ScrollableText from '@/components/ScrollableText';
import Navigation from '@/components/Navigation';
import Softline82Tabs from '@/components/products/Softline82Tabs.vue';
import Softline76Tabs from '@/components/products/Softline76Tabs.vue';
import Artline82 from '@/components/products/Artline82.vue';
import PrevNext from '@/components/PrevNext.vue';
import VideoTabs from '@/components/products/VideoTabs.vue';

export default {
    name: 'Presentation',
    components: {
        ScrollableText,
        Navigation,
        Softline82Tabs,
        Softline76Tabs,
        Artline82,
        PrevNext,
        VideoTabs
    },
    mounted() {
        Reveal.initialize({
            width: '100%',
            height: '100%',
            margin: 0,
            minScale: 1,
            maxScale: 1,
            keyboard: false,
            controls: false,
            touch: false,
            autoSlide: false,
            viewDistance: 3,
            mobileViewDistance: 2
        });
    },
    methods: {
        setVideoSrc(tab) {
            if (tab == 0) {
                this.$refs.videoSrc.src =
                    this.$utils.screenRatio() +
                    '/video/sequenz-werbemassnahmen-1_1920x1440.mp4';
            } else if (tab == 1) {
                this.$refs.videoSrc.src =
                    this.$utils.screenRatio() +
                    '/video/sequenz-werbemassnahmen-2_1920x1440.mp4';
            } else if (tab == 2) {
                this.$refs.videoSrc.src =
                    this.$utils.screenRatio() +
                    '/video/sequenz-werbemassnahmen-3_1920x1440.mp4';
            } else if (tab == 3) {
                this.$refs.videoSrc.src =
                    this.$utils.screenRatio() +
                    '/video/sequenz-werbemassnahmen-4_1920x1440.mp4';
            }

            var video = this.$refs.videoBackground;
            video.load();

            var playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function() {
                        // Automatic playback started!
                    })
                    .catch(function() {});
            }
        }
    }
};
</script>

<style>
@import url('../../node_modules/reveal.js/dist/reveal.css');
@import url('../../node_modules/reveal.js/dist/theme/white.css');

#logo-startseite {
    position: absolute;
    right: 60px;
    bottom: 60px;
}

.reveal {
    height: 100vh;
}

.reveal img,
.reveal video,
.reveal iframe {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}

section {
    width: 100%;
    height: 100%;
}
.content-container {
    width: 60%;
    margin-left: 40%;
}
.fragment {
    position: absolute;
    top: 0;
}
</style>
