/* eslint-disable no-console */

import { register } from 'register-service-worker';

import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const notyf = new Notyf({
    types: [
        {
            type: 'info',
            background: '#034991',
            icon: false
        }
    ]
});

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');

            notyf.open({
                type: 'info',
                message:
                    'Die Image Broschüre kann nun im Offline-Modus verwendet werden.',
                duration: 4000,
                dismissible: true
            });
        },
        updatefound() {
            console.log('New content is downloading.');

            notyf.open({
                type: 'info',
                message: 'Neue Inhalte werden geladen.',
                duration: 4000,
                dismissible: true
            });
        },
        updated() {
            console.log('New content is available; please refresh.');
            var r = confirm('Neue Inhalte verfügbar!');
            if (r == true) {
                window.location.reload(true);
            }
        },
        offline() {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );

            notyf.success({
                message: 'Die Image Broschüre wird im Offline-Modus verwendet.',
                duration: 4000,
                dismissible: true
            });

            var myobj = document.getElementById('overlay');
            if (myobj) {
                myobj.remove();
            }
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
