var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navigation'),_c('ScrollableText'),_c('div',{staticClass:"reveal"},[_c('div',{staticClass:"slides"},[_c('section',[_c('section',{staticClass:"1",attrs:{"data-transition":"slide","data-autoslide":"0"}},[_c('img',{staticClass:"object-cover h-screen w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/loading-veka_1920x1440.jpg'}})]),_c('section',{staticClass:"2",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade","data-autoslide":"4000"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"preload":"none","playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/welcome-start_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/welcome-start_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"3",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade","data-autoslide":"0"}},[_c('img',{staticClass:"object-cover w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/veka-werk_1920x1440.jpg'}})]),_c('section',{staticClass:"4",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"preload":"none","playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-stop":"20000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/fliegende-enten_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/fliegende-enten_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-0 content-container absolute flex items-center justify-center",staticStyle:{"top":"50px"},attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/ehepaar-hartleif-gemeinsam-nach-vorn.png'}})])]),_c('section',{staticClass:"5",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('img',{staticClass:"object-cover h-full w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/vorstand-ohne-namen_1920x1440.jpg'}}),_c('img',{staticClass:"object-cover h-full w-full fragment fade-in",staticStyle:{"z-index":"0"},attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/vorstand-mit-namen_1920x1440.jpg'}})]),_c('section',{staticClass:"6",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade","data-autoslide":"0"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"preload":"none","playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/zusammen-stark_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/zusammen-stark_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center",attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/beckhoff-zusammen-stark.png'}})])]),_c('section',{staticClass:"7",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade","data-autoslide":"500"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-gut-gemacht-sterne_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-gut-gemacht-sterne_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center",attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/schuler-gut-gemacht-sterne.png'}})])]),_c('section',{staticClass:"8",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('img',{staticClass:"object-cover h-full w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/passend-gemacht_1920x1440.jpg'}}),_c('div',{staticClass:"element transition-all duration-500 ease-in-out content-container absolute inset-0 flex items-center justify-center opacity-0",attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/passend-gemacht.png'}})])]),_c('section',{staticClass:"vielfalt-mit-system",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/background/passend-gemacht_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/passend-gemacht_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"noch-mehr-vielfalt",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/background/passend-gemacht_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/vielfalt-mit-system_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"9",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-profil_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-profil_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"10",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('img',{staticClass:"object-cover h-screen w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/standbild-softline82_1920x1080.jpg'}}),_c('div',{staticClass:"content-container absolute inset-0 flex items-center justify-center"},[_c('Softline82Tabs')],1)]),_c('section',{staticClass:"artline-82",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-artline_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-artline_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-100 content-container absolute inset-0 flex items-center justify-center",attrs:{"data-wait":"3500"}},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex justify-end"},[_c('img',{staticStyle:{"max-width":"200px","max-height":"45px"},attrs:{"data-src":_vm.$utils.screenRatio() +
                                            '/img/product/artline82/artline82-logo.png'}})]),_c('Artline82')],1)])]),_c('section',{staticClass:"softline-76",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('img',{staticClass:"object-cover h-screen w-full",attrs:{"data-src":_vm.$utils.screenRatio() +
                                '/img/background/einfamilienhaus-dunkel_1920x1440.jpg'}}),_c('div',{staticClass:"content-container absolute inset-0 flex items-center justify-center"},[_c('Softline76Tabs')],1)]),_c('section',{staticClass:"vekamotion-82",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","loop":"","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/vekamotion-bilder-sequenz_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/vekamotion-bilder-sequenz_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"11",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-haustueren_1920x1440.jpg',"id":"sequenz-haustueren"},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-haustueren_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-100 content-container absolute inset-0 flex items-center justify-center",attrs:{"data-wait":"5000"}},[_c('VideoTabs')],1)]),_c('section',{staticClass:"vekavariant2-0",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/vekavariant-rolladenkasten_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/vekavariant-rolladenkasten_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"das-rollladenkastensystem-fuer-jede-anforderung",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-vekavariant-diagramm_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"veka-sichtschutzsysteme",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-sichtschutzsysteme_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-sichtschutzsysteme_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"14",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","loop":"","muted":"","data-stop":"3000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/rolladenstaebe_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/rolladenstaebe_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"ihre-kunden-werden-augen-machen",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('PrevNext',{staticClass:"absolute z-10",staticStyle:{"top":"50px","right":"50px"},on:{"activeTab":function($event){return _vm.setVideoSrc($event)}}}),_c('video',{ref:"videoBackground",staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-werbemassnahmen-alle_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{ref:"videoSrc",attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-werbemassnahmen-1_1920x1440.mp4',"type":"video/mp4"}})])],1),_c('section',{staticClass:"exklusiv-fuer-veka-partner-veka-spectral",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-vekaspectral_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-vekaspectral_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"oberflaechengestaltung-mit-veka-profilen",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-oberflaechengestaltung_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-oberflaechengestaltung_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"robust-und-wertig-alu-vorsatzblenden",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","loop":"","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-alu-vorsatzblenden_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-alu-vorsatzblenden_1920x1440.mp4',"type":"video/mp4"}})])]),_c('section',{staticClass:"15",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-zusammen-wachsen_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-zusammen-wachsen_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out content-container absolute flex items-center justify-center opacity-0",staticStyle:{"top":"50px"},attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/zusammen-wachsen.png'}})])]),_c('section',{staticClass:"exklusiv-fuer-veka-partner",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticStyle:{"position":"absolute","top":"0","right":"0"},attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-dbs-logos_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-dbs-logos_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out content-container absolute inset-0 flex items-center justify-center opacity-0",attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/scholle-gemeinsam.png'}})])]),_c('section',{staticClass:"16",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-nachhaltigkeit_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-nachhaltigkeit_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-0 content-container absolute flex items-center justify-center",staticStyle:{"top":"50px"},attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/hartleif-nachhaltigkeit.png'}})])]),_c('section',{staticClass:"ganz-individuell",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","data-wait":"4000","poster":_vm.$utils.screenRatio() +
                                '/img/poster/sequenz-tabelle-projektteam_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/sequenz-tabelle-projektteam_1920x1440.mp4',"type":"video/mp4"}})]),_c('div',{staticClass:"element transition-all duration-500 ease-in-out opacity-0 content-container absolute inset-0 flex items-center justify-center",attrs:{"data-wait":"4000"}},[_c('img',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/img/zitat/hartleif-tabelle-projektteam.png'}})])]),_c('section',{staticClass:"veka-ende",staticStyle:{"transition-duration":"2s"},attrs:{"data-transition":"fade"}},[_c('video',{staticClass:"object-cover h-screen w-full",attrs:{"playsinline":"","autobuffer":"","crossOrigin":"anonymous","muted":"","poster":_vm.$utils.screenRatio() +
                                '/img/poster/abschluss_1920x1440.jpg'},domProps:{"muted":true}},[_c('source',{attrs:{"data-src":_vm.$utils.screenRatio() +
                                    '/video/abschluss_1920x1440.mp4',"type":"video/mp4"}})])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }