<template>
    <div>
        <div class="flex">
            <img
                :src="$utils.screenRatio() + '/img/icon/scroll-weiss.png'"
                class="cursor-pointer"
                style="
                    max-width: 40px;
                    max-height: 40px;
                    transform: rotate(90deg);
                "
                alt=""
                @click="prev()"
            />
            <img
                :src="$utils.screenRatio() + '/img/icon/scroll-weiss.png'"
                class="cursor-pointer"
                style="
                    max-width: 40px;
                    max-height: 40px;
                    transform: rotate(-90deg);
                "
                alt=""
                @click="next()"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrevNext',
    data() {
        return {
            index: 0
        };
    },
    methods: {
        next() {
            this.index++;

            if (this.index >= 4) {
                this.index = 0;
            }
            this.$emit('activeTab', this.index);
        },
        prev() {
            this.index--;

            if (this.index <= 0) {
                this.index = 3;
            }
            this.$emit('activeTab', this.index);
        }
    }
};
</script>

<style></style>
